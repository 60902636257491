var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.getData),expression:"getData"}],staticClass:"fangtan_phone",attrs:{"infinite-scroll-distance":"10","infinite-scroll-disabled":"busy"}},[_c('el-row',{staticClass:"crumbs",attrs:{"type":"flex","justify":"center"}},[_c('el-col',[_c('el-breadcrumb',{attrs:{"separator":">"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/' }}},[_vm._v("社科视频首页")]),_c('el-breadcrumb-item',[_vm._v("待审核视频")])],1)],1)],1),_c('div',{staticClass:"container"},[_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',[_c('ViceHeadline',{attrs:{"title":'待审核视频'}}),_c('el-row',{attrs:{"gutter":_vm.$store.state.isEquipment == 'pc'?40:0}},_vm._l((_vm.list),function(item){return _c('el-col',{key:item.index,attrs:{"xs":24,"sm":24,"md":8,"lg":8,"xl":8}},[_c('router-link',{attrs:{"target":"_blank","to":{
                path: '/privateDetails',
                query: {
                  // key: item.KEYWORDS,
                  qing: item.id,
                  // xiang: item.doclibId,
                  // xiangdoc: item.docId,
                  mian: 'review',
                },
              }}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"coverWrap"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.pub_cover),expression:"item.pub_cover"}],staticClass:"cover",attrs:{"alt":""}}),_c('div',{staticClass:"duration"},[_c('p',[_vm._v(_vm._s(item.TIME_LENGTH))])]),_c('img',{staticClass:"pause",attrs:{"src":require("@/assets/image/zixun/播放.png"),"alt":""}})]),_c('div',{staticClass:"card_content"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.SYS_TOPIC))]),_c('p',{staticClass:"time"},[_vm._v(_vm._s(_vm.$ModuleDate(item.RECORD_TIME)))])])])])],1)}),1)],1)],1)],1),_c('div',{staticClass:"loading"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.busy),expression:"busy"}],staticClass:"busy"},[_vm._v("加载中...")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.finish),expression:"finish"}],staticClass:"finish"},[_vm._v("没有更多了")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }